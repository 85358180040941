import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Contacts } from './routes/Contacts';
import { Home } from './routes/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contacts' element={<Contacts />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
